import * as React from "react";
import userflow from "userflow.js";
import { CurrentUserDto } from "@server-types/users";
import { HelpModalContext } from "./HelpModal";

const useToggleHelp = (
  user: CurrentUserDto | null,
  organisationId: string,
  organisationName: string
) => {
  const helpModalController = React.useContext(HelpModalContext);
  // Track if Userflow loaded correctly.
  // This might stay false if their scripts are blocked/broken.
  const [isUserflowLoaded, setIsUserflowLoaded] = React.useState(false);

  const toggle = React.useCallback(() => {
    if (isUserflowLoaded) {
      userflow.toggleResourceCenter();
    } else {
      // Fallback to just showing our own help modal.
      helpModalController.open(true);
    }
  }, [helpModalController, isUserflowLoaded]);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    async function userflowInit() {
      try {
        await userflow.load();

        userflow.init(user.userflowToken);

        // Don't show the Userflow launcher button because it
        // is fixed to bottom-right and overlaps some of our report UI.
        // We'll toggle the help center via our own navbar link.
        userflow.setResourceCenterLauncherHidden(true);

        userflow.setUrlFilter(url => {
          const parsed = new URL(url);

          // Remove sensitive params from query string
          const paramsToFilter = ["pupilQuery", "filter"];
          paramsToFilter.forEach(key => {
            if (parsed.searchParams.has(key)) {
              parsed.searchParams.delete(key);
            }
          });

          parsed.search = parsed.searchParams.toString();

          if (parsed.pathname.search("pupils") !== -1) {
            const pathParts = parsed.pathname.split("/");

            // Anonymize pupil upns for pupil page
            if (pathParts[pathParts.length - 1] !== "pupils") {
              const pupilsPartIndex = pathParts.findIndex(p => p === "pupils");
              pathParts[pupilsPartIndex + 1] = "xxxxxx";
              parsed.pathname = pathParts.join("/");
            }
          }

          return parsed.toString();
        });

        // userflow.identify could also fail e.g. if Websockets are blocked in some mysterious way
        await userflow.identify(user.externalId, {
          signed_up_at: user.activatedAt,
          access_level: user.accessLevel,
        });

        await userflow.group(organisationId, {
          name: organisationName,
        });

        // I'm not sure about edge cases of networks blocking/corrupting the Userflow script.
        // If Userflow has successfully loaded itself, then I think _stubbed is false.
        if (!userflow._stubbed) {
          setIsUserflowLoaded(true);
        }
      } catch {
        // Swallow userflow loading error.
        // Page will keep using our own help modal.
      }
    }

    userflowInit();
  }, [user, organisationId, organisationName]);

  return toggle;
};

export default useToggleHelp;
