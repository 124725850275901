import { insightClientVersion } from "./insightClientVersion";

const Unauthorized = 401;
const Conflict = 409;

/** Prevents concurrent calls to insightFetch from both triggering the alert and redirect. */
let handlingUnauth = false;

export type InsightFetchBody =
  | ReadableStream
  | XMLHttpRequestBodyInit
  | Record<string, unknown>;

type InsightFetchRequestInit = Omit<RequestInit, "body"> & {
  body?: InsightFetchBody;
};

export async function insightFetch(
  input: RequestInfo,
  init?: InsightFetchRequestInit
): Promise<Response> {
  ensureSafeHttpMethod(init);

  const headers = new Headers(init?.headers);
  if (!headers.has("Accept")) {
    headers.set("Accept", "application/json");
  }

  // This header is used by ASP.NET to determine if the request is an
  // AJAX request. Then if the request is unauthenticated, it will
  // return a 401 response, instead of redirecting to the login page.
  headers.set("X-Requested-With", "XMLHttpRequest");

  if (insightClientVersion) {
    headers.set("X-Insight-Client-Version", insightClientVersion.toString());
  }

  let response: Response;
  let body: InsightFetchRequestInit["body"];

  if (
    init?.body instanceof Object &&
    !(init?.body instanceof Blob) &&
    !(init?.body instanceof FormData) &&
    !(init?.body instanceof URLSearchParams)
  ) {
    body = JSON.stringify(init?.body);
    headers.set("Content-Type", "application/json");
  } else {
    body = init?.body;
  }

  try {
    // This should be the only place we call fetch directly.
    // An eslint rule will error for other uses of fetch.
    // eslint-disable-next-line no-restricted-syntax
    response = await fetch(input, {
      ...init,
      body,
      credentials: "same-origin",
      headers,
    });
  } catch {
    // We'll catch network errors here and return a custom response.
    // This saves us having to worry about catching these errors elsewhere.
    return new Response(
      '{ "message": "Unable to connect to Insight server." }',
      {
        status: 555,
        statusText: "Unable to connect to Insight server.",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  if (response.status === Unauthorized && !handlingUnauth) {
    handlingUnauth = true;
    alert("Your Insight session has expired. Please log in again.");
    redirectToLogin();
    return new Promise(() => {
      // This promise never resolves.
      // This prevents the calling async code from doing anything else.
    });
  }

  // Server may return a 409 Conflict status code to indicate that the client's
  // version is not supported.
  // This is a signal to the client to reload the page.
  if (response.status === Conflict) {
    alert("Sorry, Insight needs to reload to apply an update.");
    location.reload();
    return new Promise(() => {
      // This promise never resolves.
      // This prevents the calling async code from doing anything else.
    });
  }

  if (
    response.status === 400 &&
    response.headers.get("content-type")?.startsWith("application/json")
  ) {
    // There are places that use response.text() but we are using response.json() here.
    // Clone the response so that the body stream can be read again.
    const responseClone = response.clone();
    const errorBody = await response.json();
    // Insight server tends to include a `message` property for 400 responses.
    // However, the [ApiController] attribute will serialize problems using
    // format defined in https://tools.ietf.org/html/rfc7231#section-6.5.1
    // This has a `title` property.
    // Copy `title` into `message` so the calling code can be consistent.
    if (
      errorBody?.type === "https://tools.ietf.org/html/rfc7231#section-6.5.1"
    ) {
      errorBody.message = errorBody.title;

      // We've already read the JSON, but the caller may need to do that again.
      // Add our own json() function that returns the new errorBody.
      responseClone.json = () => new Promise(resolve => resolve(errorBody));
    }

    return responseClone;
  }

  return response;
}

/**
 * Some customer networks block HTTP methods like PATCH.
 * So rewrite to POST and add a header to indicate the original method.
 */
function ensureSafeHttpMethod(init: InsightFetchRequestInit) {
  const method = init?.method?.toUpperCase();
  if (method && method !== "GET" && method !== "POST") {
    init.headers = { ...init.headers, "X-HTTP-Method-Override": method };
    init.method = "POST";
  }
}

function redirectToLogin() {
  const login = new URL("/login", location.origin);
  login.searchParams.append(
    "returnUrl",
    location.pathname + location.search + location.hash
  );
  location.assign(login.toString());
}
