import { useEffect, useReducer, useState } from "react";
import { GetSchoolLayoutData } from "@server-types/schools";
import { GetTrustLayoutData } from "@server-types/trusts";
import { GetRootLayoutData } from "@server-types/users";
import { insightClientVersion } from "../insightClientVersion";
import { insightFetch } from "../insightFetch";

type LayoutData = GetRootLayoutData | GetSchoolLayoutData | GetTrustLayoutData;

type FetchLayoutState =
  | { type: "loading" }
  | { type: "success"; layout: LayoutData }
  | { type: "error"; error: Error };

export function useFetchLayout(
  layoutUrl: string
): [FetchLayoutState, VoidFunction] {
  layoutUrl = appendClientVersionToUrl(layoutUrl);
  const [layout, setLayout] = useState<FetchLayoutState>({ type: "loading" });
  const [refreshToken, refresh] = useReducer(x => x + 1, 0);

  useEffect(() => {
    let cancelled = false;
    async function load() {
      try {
        const response = await insightFetch(layoutUrl, {
          // If refreshing, then don't want to use cache.
          cache: refreshToken === 0 ? "default" : "no-cache",
        });

        if (cancelled) {
          return;
        }

        if (!response.ok) {
          throw new Error("Error fetching layout data");
        }

        const data = await response.json();
        setLayout({ type: "success", layout: data });
      } catch (error) {
        if (cancelled) {
          return;
        }

        setLayout({ type: "error", error });
      }
    }

    load();

    return () => {
      cancelled = true;
    };
  }, [layoutUrl, setLayout, refreshToken]);

  return [layout, refresh];
}

// Append client version to layout URL if it doesn't already have it.
// Doing this here instead of changing every layout URL creation on the
// server-side!
function appendClientVersionToUrl(url: string) {
  if (insightClientVersion && url.indexOf("cv=") < 0) {
    const separator = url.indexOf("?") < 0 ? "?" : "&";
    return `${url}${separator}cv=${insightClientVersion}`;
  } else {
    return url;
  }
}
