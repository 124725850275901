import * as React from "react";
import { pupilQueryStorage } from "@/shared/PupilQuery2/pupilQueryStorage";
import { AcademicYear, AttendanceThreshold } from "@server-types/schools";
import allYearGroups from "./allYearGroups";

export type AppConfig = {
  organisation: OrganisationInfo;
  currentAcademicYear: AcademicYear;
  defaultMarkSchemeId: string;
  markSchemes: MarkSchemeInfo[];
  assessmentYearGroups: YearGroupInfo[];
  assessmentFrequency: AssessmentFrequency;
  pupilYearGroups: YearGroupInfo[];
  terms: TermInfo[];
  currentTerm: Term;
  previousTerm: Term;
  subjects: SubjectOrGroupInfo[];
  assessmentTypeGroups: AssessmentTypeGroup[];
  attainmentEvaluations: AttainmentEvaluationInfo[];
  attendanceThresholds: AttendanceThreshold[];
  features: string[];
};

export type OrganisationInfo = {
  type: "School" | "Trust";
  name: string;
  shortId: string;
};
type AppConfigRefreshFunction = VoidFunction;

export type AttainmentEvaluationInfo = {
  evaluation: Evaluation;
  name: string;
};

export type MarkSchemeInfo = {
  id: string;
  name: string;
  marks: null | MarkInfo[];
};

export type MarkInfo = {
  id: string;
  name: string;
};

export type YearGroupInfo = {
  number: number;
  name: string;
  shortName: string;
};

export type TermInfo = {
  id: Term;
  name: string;
  shortName: string;
};

export type SubjectInfo = {
  name: string;
};

export type SubjectGroupInfo = {
  name: string;
  subjects: SubjectInfo[];
};

export type SubjectOrGroupInfo = SubjectGroupInfo | SubjectInfo;

export type AssessmentTypeGroup = {
  name: string;
  assessmentTypes: AssessmentType[];
};

export type SingleAssessmentType = {
  name: string;
  readonly: boolean;
  subjects: string[];
  markSchemeIds: string[];
};

export type MultiComponentAssessmentType = {
  name: string;
  readonly: boolean;
  components: AssessmentComponentInfo[];
};

export type AssessmentComponentInfo = {
  name: string;
  subjects: string[];
  markSchemeId: string;
  assessmentName: string;
  assessmentTypeName: string;
};

export type AssessmentType =
  | SingleAssessmentType
  | MultiComponentAssessmentType
  | AssessmentComponentInfo;

export function isSingleAssessmentType(
  type: AssessmentType
): type is SingleAssessmentType {
  return type && (type as MultiComponentAssessmentType).components === null;
}

export function isMultiComponentAssessmentType(
  type: AssessmentType
): type is MultiComponentAssessmentType {
  return (
    type &&
    (type as MultiComponentAssessmentType).components !== undefined &&
    (type as MultiComponentAssessmentType).components !== null
  );
}

export function isAssessmentComponent(
  type: AssessmentType
): type is AssessmentComponentInfo {
  return type && (type as AssessmentComponentInfo).markSchemeId !== undefined;
}

export type AssessmentFrequency = "HalfTermly" | "Termly";

export type Term =
  | "Entry"
  | "Autumn1"
  | "Autumn2"
  | "Spring1"
  | "Spring2"
  | "Summer1"
  | "Summer2";

export type Evaluation = "None" | "Bad" | "Warning" | "Expected" | "Excellent";

export const AppConfigContext = React.createContext<
  AppConfig & { refresh: AppConfigRefreshFunction }
>(null);

export function AppConfigProvider({
  appConfig,
  refresh,
  children,
}: {
  appConfig: AppConfig;
  refresh: AppConfigRefreshFunction;
  children: React.ReactChild;
}) {
  // Once appConfig is available, initialise the pupil query storage
  // with the current academic year.
  // useMemo to ensure it only runs once.
  const contextValue = React.useMemo(() => {
    if (
      appConfig &&
      typeof pupilQueryStorage.currentAcademicYear === "undefined"
    ) {
      pupilQueryStorage.init(appConfig.currentAcademicYear);
    }
    return { ...appConfig, refresh };
  }, [appConfig, refresh]);

  return (
    <AppConfigContext.Provider value={contextValue}>
      {appConfig && children}
    </AppConfigContext.Provider>
  );
}

export function useAppConfigContext() {
  return React.useContext(AppConfigContext);
}

export function useAppConfigRefresh(): AppConfigRefreshFunction {
  return useAppConfigContext().refresh;
}

function useConfigValue<T extends keyof AppConfig>(
  key: T,
  override?: AppConfig[T],
  fallback?: AppConfig[T]
): AppConfig[T] {
  const config = useAppConfigContext();

  if (override) return override;
  if (config) return config[key];
  if (fallback) return fallback;

  throw new Error(
    `${key} is not available via config in context, and no override was provided.`
  );
}

// Export React hooks that provide access the config store in context.

export function useCurrentAcademicYear(): number {
  const config = useAppConfigContext();
  // If the config is not available, use the Insight default academic year.
  return config?.currentAcademicYear ?? defaultCurrentAcademicYear();
}

function defaultCurrentAcademicYear(): number {
  const now = new Date();
  return now.getUTCFullYear() - (now.getUTCMonth() < 8 ? 1 : 0);
}

/** Gets the subjects from the config in context, unless an override is provided. */
export function useSubjects(
  override?: SubjectOrGroupInfo[]
): SubjectOrGroupInfo[] {
  return useConfigValue("subjects", override);
}

/** Gets the assessment type groups from the config in context, unless an override is provided. */
export function useAssessmentTypeGroups(
  override?: AssessmentTypeGroup[]
): AssessmentTypeGroup[] {
  return useConfigValue("assessmentTypeGroups", override);
}

/** Gets the assessment year groups from the config in context, unless an override is provided. */
export function useAssessmentYearGroups(
  override?: YearGroupInfo[]
): YearGroupInfo[] {
  return useConfigValue("assessmentYearGroups", override, allYearGroups);
}

/** Gets the assessment frequency from the config in context, unless an override is provided. */
export function useAssessmentFrequency(
  override?: AssessmentFrequency
): AssessmentFrequency {
  return useConfigValue("assessmentFrequency", override);
}

/** Gets the pupil year groups from the config in context, unless an override is provided. */
export function usePupilYearGroups(
  override?: YearGroupInfo[]
): YearGroupInfo[] {
  return useConfigValue("pupilYearGroups", override, allYearGroups);
}

const allTerms: TermInfo[] = [
  { id: "Entry", name: "Entry", shortName: "Ent" },
  { id: "Autumn1", name: "Autumn 1", shortName: "Aut1" },
  { id: "Autumn2", name: "Autumn 2", shortName: "Aut2" },
  { id: "Spring1", name: "Spring 1", shortName: "Spr1" },
  { id: "Spring2", name: "Spring 2", shortName: "Spr2" },
  { id: "Summer1", name: "Summer 1", shortName: "Sum1" },
  { id: "Summer2", name: "Summer 2", shortName: "Sum2" },
];

/** Gets the terms from the config in context, unless an override is provided. */
export function useTerms(override?: TermInfo[]): TermInfo[] {
  return useConfigValue("terms", override, allTerms);
}

/** Get the current term from config in context. */
export function useCurrentTerm(): Term {
  return useConfigValue("currentTerm");
}

/** Get the previous term from config in context. */
export function usePreviousTerm(): Term {
  return useConfigValue("previousTerm");
}

/** Gets the mark schemes from the config in context, unless an override is provided. */
export function useMarkSchemes(override?: MarkSchemeInfo[]): MarkSchemeInfo[] {
  return useConfigValue("markSchemes", override);
}

export function useDefaultMarkSchemeId(): string {
  return useConfigValue("defaultMarkSchemeId");
}

export function useAttainmentEvaluations() {
  return useConfigValue("attainmentEvaluations");
}

export function useAttendanceThresholds() {
  return useConfigValue("attendanceThresholds");
}

export function useOrganisation() {
  return useConfigValue("organisation");
}

export function useFeatures() {
  return useConfigValue("features") ?? [];
}

export function findAssessmentType(
  assessmentTypeGroups: AssessmentTypeGroup[],
  assessmentName: string
): AssessmentType | null {
  for (const group of assessmentTypeGroups) {
    for (const type of group.assessmentTypes) {
      if (type.name === assessmentName) {
        return type;
      }
      if (isMultiComponentAssessmentType(type)) {
        for (const component of type.components) {
          if (component.assessmentName === assessmentName) {
            return component;
          }
        }
      }
    }
  }

  return null;
}

/** Usually this is "Main Assessment". */
export function useDefaultAssessmentName() {
  const groups = useAssessmentTypeGroups();
  return groups[0]?.assessmentTypes[0]?.name ?? null;
}
