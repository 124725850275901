import React from "react";
import { Alert } from "../Alert";
import { AppConfig, AppConfigProvider } from "../AppConfig";
import Spinner from "../Spinner";
import { CurrentUserProvider } from "../useCurrentUser";
import {
  HelpModal,
  HelpModalContext,
  useHelpModalController,
} from "./HelpModal";
import LogErrorToServer, { postErrorToServer } from "./LogErrorToServer";
import { MyAccountMenu } from "./Menus";
import { PageHiddenForPrivateMode } from "./PageHiddenForPrivateMode";
import SchoolNavBar from "./SchoolNavBar";
import TrustNavBar from "./TrustNavBar";
import { useFetchLayout } from "./useFetchLayout";
import styles from "./index.css";

// AppFrames are designed to be very top-level of React app page.
// They include error boundary and nav bar.

type LayoutProps = {
  /** URL to get layout data from. */
  layoutUrl: string;
  hideInPrivateMode?: boolean;
  children: React.ReactNode;
};

/** Layout for either school or trust page.
 *
 * The type of layout is determined by what the layout data's OrganisationType is. */
export function AppFrame({
  layoutUrl,
  hideInPrivateMode,
  children,
}: LayoutProps) {
  const [layoutState, refresh] = useFetchLayout(layoutUrl);
  const helpModalController = useHelpModalController();

  if (layoutState.type === "loading") {
    return <Loading />;
  }

  if (layoutState.type === "error") {
    return <ErrorLoadingLayout />;
  }

  const layout = layoutState.layout;

  if (!("organisationType" in layout)) {
    // This should not happen! Invalid layout response?
    postErrorToServer(
      new Error("Layout data does not have an organisationType property.")
    );
    return <ErrorLoadingLayout />;
  }

  return (
    <LogErrorToServer>
      <AppConfigProvider
        // Awkward cast here due to difference in generated AppConfig type!
        appConfig={layout?.appConfig as AppConfig}
        refresh={refresh}
      >
        <CurrentUserProvider>
          <HelpModalContext.Provider value={helpModalController}>
            <div className={styles.container}>
              {layout.organisationType === "School" && (
                <SchoolNavBar layout={layout} />
              )}

              {layout.organisationType === "Trust" && (
                <TrustNavBar layout={layout} />
              )}

              {helpModalController.isOpen && (
                <HelpModal
                  helpUrl={layout.links.help}
                  onClose={helpModalController.close}
                  isUserflowBlocked={helpModalController.isUserflowBlocked}
                />
              )}

              {hideInPrivateMode && isPrivateMode() ? (
                <PageHiddenForPrivateMode />
              ) : (
                children
              )}
            </div>
          </HelpModalContext.Provider>
        </CurrentUserProvider>
      </AppConfigProvider>
    </LogErrorToServer>
  );
}

export function RootAppFrame({ layoutUrl, children }: LayoutProps) {
  const [layoutState] = useFetchLayout(layoutUrl);

  if (layoutState.type === "loading") {
    return <Loading />;
  }

  if (layoutState.type === "error") {
    return <ErrorLoadingLayout />;
  }

  return (
    <LogErrorToServer>
      <div className={styles.container}>
        {layoutState.layout ? (
          <div className={styles.navbar}>
            <div className={styles.secondaryNav}>
              <MyAccountMenu links={layoutState.layout.links} />
            </div>
          </div>
        ) : null}

        {children}
      </div>
    </LogErrorToServer>
  );
}

export function EmptyAppFrame({ children }: { children: React.ReactNode }) {
  const helpModalController = useHelpModalController();

  return (
    <LogErrorToServer>
      <HelpModalContext.Provider value={helpModalController}>
        {children}

        {helpModalController.isOpen && (
          <HelpModal
            organisations={helpModalController.organisations}
            helpUrl="/api/help"
            onClose={helpModalController.close}
            isUserflowBlocked={helpModalController.isUserflowBlocked}
          />
        )}
      </HelpModalContext.Provider>
    </LogErrorToServer>
  );
}

function Loading() {
  return (
    <div style={{ padding: "20px" }}>
      <Spinner /> Loading page...
    </div>
  );
}

function ErrorLoadingLayout() {
  return (
    <div style={{ padding: "20px" }}>
      <Alert type="error">
        There was a problem loading the page. Please try reloading.
      </Alert>
    </div>
  );
}

const isPrivateMode = () =>
  document.cookie
    .split(";")
    .map(c => c.trim())
    .filter(c => c === "private-mode=1").length > 0;
