import React from "react";
import { createRoot } from "react-dom/client";
import { LoginPageData } from "@server-types/public";
import { EmptyAppFrame } from "../shared/AppFrame";
import { App } from "./App";

export declare const pageData: LoginPageData;

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <EmptyAppFrame>
    <App pageData={pageData} />
  </EmptyAppFrame>
);
